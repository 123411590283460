import React from 'react';

import {
    indigo,
    pink,
} from '@material-ui/core/colors';

export const PRIMARY_COLOR = indigo;
export const SECONDARY_COLOR = pink;

export const GOOGLE_RECAPTCHA_SITE_KEY = __GOOGLE_RECAPTCHA_SITE_KEY__;
export const GOOGLE_TAG_MANAGER_ARGS = {
    gtmId: __GOOGLE_TAG_MANAGER_ID__,
};
//export const NEWSLETTER_SERVER_URL = __NEWSLETTER_SERVER_URL__;



/////////////////////////////////////////////////////////
//                                                     //
//          Configuration OpenStreetMap (OSM)          //
//                                                     //
/////////////////////////////////////////////////////////

export const OSM_MAP_NAME = "OpenStreetMap_France";

export const OSM_MARKER_ICON_RETINA_URL = "/static/img/leaflet/dist/images/marker-icon-2x.png";
export const OSM_MARKER_ICON_URL = "/static/img/leaflet/dist/images/marker-icon.png";
export const OSM_MARKER_SHADOW_URL = "/static/img/leaflet/dist/images/marker-shadow.png";



//////////////////////////////////////////////////////
//                                                  //
//          Bouton d'action flottant (FAB)          //
//                                                  //
//////////////////////////////////////////////////////

// Icones utilisées
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';
import EventIcon from '@material-ui/icons/Event';

// Liste des actions (= boutons) contenues dans le "FAB"
export const FAB_ACTIONS = [
    { icon: <EventIcon color="primary" />, name: 'Agenda', url: "/vie-locale/agenda/" },
    { icon: <MenuBookIcon color="primary" />, name: 'Ressource documentaire', url: "/ressource-documentaire/" },
    { icon: <SearchIcon color="primary" />, name: 'Rechercher', url: "/rechercher/" },
];



/////////////////////////////////////////////////////
//                                                 //
//          Icones utilisées dans le menu          //
//                                                 //
/////////////////////////////////////////////////////

export const MENU_ICON_MAPPING = {
}
